import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Stapleton Designs — Custom printed goods for weddings and festive events" />
    
    <div class="blob-wrap x">
      <div class="blob y"></div>
    </div>

    <div className="content top">
      <div className="container">
        <p className="font-hero font-weight-600"><Link to="/">Stapleton Designs</Link></p>
      </div>
    </div>
    <div className="content bottom" style={{ maxWidth: 540 }}>
      <div className="container">
        <p><span className="font-weight-600">Coming Soon</span> &mdash; Alluring and sexy custom printed goods for weddings and festive events.</p>
        <a className="btn no-left-right-margin no-bottom-margin" href="mailto:hello@stapletondesigns.com" target="_blank" rel="noreferrer">Send Email</a>
      </div>
    </div>
  </Layout>
)

export default IndexPage
